.customer-service-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.customer-service-header {
  padding: 10px;
  background-color: #000000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.customer-service-body {
  padding: 10px;
}

.customer-service-body textarea {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}

.customer-service-footer {
  padding: 10px;
  border-radius: 5px;
}

.floating-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000000;
  color: rgb(255, 255, 255);
  border-radius: 20%;
  padding: 10px;
  cursor: pointer;
}