.tasks-container form {
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  align-items: center;
}

.inputTaskExport {
  background-color: black;
  color: white;
  border: solid;
  border-color: white;
  border-width: 1px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  font-size: 14px;
  width: 280px;
  height: 20px;
  font-style: italic;
}

.selectTaskExport {
  color: white;
  background-color: black;
  border: solid;
  border-color: white;
  border-width: 1px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  font-size: 14px;
  width: 300px;
  height: 40px;
  font-style: italic;
}