.contactFormMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactFormSecond {
    display: flex;
    flex-direction: row;
    margin: 20px;
    margin-top: 150px;
}

.contactFormThird {
    flex-direction: row;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76); /* Horizontal offset, vertical offset, blur radius, color */
}

.contactFormMain form {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: center;
    align-items: center;
  }

.label {
    margin-top: 20px;
}

.bottombutton {
    color: black;
    margin-bottom: 50px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    width: 150px;
    background-color: #3d3d3519;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3);
  }

  .bottombutton:hover {
    background-color: black;
    color: white;
  }

