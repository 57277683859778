.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  margin-left: -30px;
}

.App-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; /* Set a high z-index to ensure it's on top of other content */
  background-color: #282c34;
  min-height: 10vh;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  padding: 10px;
  color: white;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
}

.App-link {
  color: #61dafb;
}

.App-header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.App-header-left a {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
}

.App-header-left a:hover {
  text-decoration: underline;
}

.App-header-right {
  display: flex;
  align-items: center;
}

.Admin-link {
  margin-right: 20px; /* Adjust the spacing as desired */
}

.Settings-icon {
  color: rgb(255, 255, 255);
  margin-right: 30px;
  cursor: pointer;
}

.Shopping-icon {
  color: rgb(255, 255, 255);
  margin-right: 20px;
  cursor: pointer;
}

.Settings-icon:hover {
  color: #d28753;
}

.Shopping-icon:hover {
  color: #d28753;
}


/* global-styles.css */



body {
  font-size: 16px;
  background-color: rgb(219, 222, 231);
  color: #132334;
}

/* Add your existing global styles here */
