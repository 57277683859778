.chartMain {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

@media screen and (min-width: 768px) {
  #weeeLineChart,
  #ceramicsLineChart {
    max-width: 100%; 
    width: 400px; 
    height: 200px;
  }
}

@media screen and (max-width: 767px) {
  #weeeLineChart,
  #ceramicsLineChart {
    width: 100%;
    height: 150px;
  }
}

@media screen and (max-width: 768px) {


  .chartMain  {
    height: 100vh;
    width: 1200px;
  }
}