.main-container {
  flex: 0.8;
  display: flex;
  width: calc(100% - 16px);
  margin: 10px;
  box-sizing: border-box;
  overflow: hidden;
  border-image-slice: 1;
  border-radius: 10px;
  padding: 10px;
  color: black;
  flex-direction: column;
  justify-content: space-around;
}

.info-container {
  flex: 0.9;
  width: calc(100% - 16px);
  margin: 10px;
  box-sizing: border-box;
  overflow: hidden;
  border: 8px solid transparent;
  border-image: linear-gradient(
    to right,
    #d28753 0%,
    #4f4c4c 10%,
    white 50%,
    rgb(153, 109, 5) 90%,
    #d28753 100%
  );
  border-image-slice: 1;
  border-radius: 10px;
  padding: 10px;
  color: black;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: colorTransition 8s linear infinite, pulsate 4s ease-in-out infinite;
  position: relative;
  /* Center the .info-container */
  margin-left: auto;
  margin-right: auto;
}

/* Rest of your CSS remains unchanged */

@keyframes colorTransition {
  0%, 100% {
    border-image-source: linear-gradient(to right, 
      #d28753 0%, 
      #4f4c4c 10%, 
      white 50%, 
      rgb(153, 109, 5) 90%, 
      #d28753 100%
    );
  }
  25% {
    border-image-source: linear-gradient(to right, 
      #4f4c4c 0%, 
      white 10%, 
      rgb(171, 105, 14) 50%, 
      #d28753 90%, 
      #4f4c4c 100%
    );
  }
  50% {
    border-image-source: linear-gradient(to right, 
      white 0%, 
      rgb(171, 105, 14) 10%, 
      #d28753 50%, 
      #4f4c4c 90%, 
      white 100%
    );
  }
  75% {
    border-image-source: linear-gradient(to right, 
      rgb(171, 105, 14) 0%, 
      #d28753 10%, 
      #4f4c4c 50%, 
      white 90%, 
      rgb(171, 105, 14) 100%
    );
  }
}

@keyframes pulsate {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
}

@media screen and (max-width: 768px) {
  .info-container {
    margin-right: 0; 
    margin-bottom: 10px; 
  }
}

