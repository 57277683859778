.stockMainComponent {
    display: flex;
    flex-direction: column;
    margin: 10px;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 90vh;
  }
  
  .menuDashboard {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  
  .menuDashboardClicks {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 120px;
    font-size: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    align-items: center;
    padding: 25px;
    margin: 20px;
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    border-color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
    height: 40px;
  }

  .dashboardIcons {
    width: 30px;
    height: 30px;
  }

  .stock-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    color: white;
    margin-top: 120px;
}

.stock-table-containers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color:#282c34;
  margin-bottom: 30px;
  border-top: solid;
  border-color: #d28753;
  border-width: 5px;
}

.h1HeadersStockDetails {
  margin: 10px;
  font-size: 22px;
  font-weight: 900;
  font-family: 'Courier New', Courier, monospace;
}

.h2HeadersStockDetails {
  margin: 10px;
  font-size: 18px;
  font-weight: 900;
  font-family: 'Courier New', Courier, monospace;
}

.h1HeadersStockDetailsBig {
  color: #282c34;
  font-size: 32px;
  font-weight: 900;
  font-family: 'Courier New', Courier, monospace;
}

.h1HeadersStockDetailsBigWhite {
  color: #ffffff;
  font-size: 32px;
  font-weight: 900;
  font-family: 'Courier New', Courier, monospace;
}

  @media screen and (max-width: 900px) {
    .menuDashboard {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        height: 100%;
      }

      .stockMainComponent {
        margin-top: 140px;
        margin-bottom: 50px;
      }
    
  }