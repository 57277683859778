/* CrgEgyptMain.css */

.crg-container {
  color: white;
  background-color: rgb(0, 0, 0);
  overflow: auto;
  
}

/* Ensure that the parent container has flex properties */


.chart-container {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
overflow-x: auto;
align-self: center; /* Centers it in the parent */
margin: 0 auto; /* Optional: Ensures centering when needed */
}


.crg-container-text {
  color: white;
}

.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: auto; /* Allows horizontal scrolling when needed */
  align-self: center;
  margin-bottom: 20px;
}

.table-crg {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  overflow-x: auto; /* Allows the table to scroll horizontally if it overflows */
}

.row-container {
  display: flex;
}

.table-section {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.table-section-debt {
  display: flex;
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.table-section:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.221);
}

.table-section-debt:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.221);
}

.row-item {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.row-item:last-child {
  border-bottom: none;
}

h1, h2 {
  color: #333;
}

p {
  margin: 5px 0;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; 
}

.data-table th,
.data-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #fff;
  width: 1%; /* Ensures cells take up space evenly */
  overflow: hidden; /* Prevents content from overflowing */
}


@media screen and (max-width: 600px) {
  .row-item {
    font-size: 14px;
  }

  .data-table {
    width: 100%;
    /* Remove min-width */
    border-collapse: collapse;
  }

  .data-table th,
  .data-table td {
    padding: 10px; /* Ensure the table cells are properly padded */
    text-align: center; /* Center-align text within table cells */
    border: 1px solid #fff; /* Add border to table cells for better visibility */
  }

  /* Optional: Adjust chart container for smaller screens */
  .chart-container {
    width: 320px; /* Make it 100% width for smaller screens */
  }
}


