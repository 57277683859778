/* Add the following styles to your existing CSS */

.goalMain {
  display: flex;
  flex-direction: row;
}

.current-goal {
  display: flex;
  flex-direction: column;
  margin: 5px;
  justify-content: center;
  align-items: center;
}

.goalChartMain {
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  margin-bottom: 15px;
  width: 96%;
}

#pie-chart,
#pie-chart-real {
  max-width: 25%; /* Ensure the chart width does not exceed the container width */
  height: auto; /* Allow the chart height to adjust accordingly */
  margin: 0 auto; /* Center the chart horizontally within the parent container */
}

.buttonRealValuesGoals {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 40px;
}

.progress-bars {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.progress-bar span {
  margin-bottom: 5px;
}

.progress-bar progress {
  width: 100%;
  height: 30px;
}

/* Customize progress bar appearance */
.progress-bar progress {
  appearance: none;
}

.progress-bar progress::-webkit-progress-bar {
  background-color: #f2f2f2; 
}

.progress-bar progress::-webkit-progress-value {
  background-color: #007bff; /* Default color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
}

.progress-bar progress::-moz-progress-bar {
  background-color: #007bff; /* Default color */
}

.progressBarItself {
  border: solid;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
}

.progress-container {
  position: relative;
  height: 20px; /* Adjust height as needed */
}

.progress-container span {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  font-size: 12px; /* Adjust font size as needed */
}


/* Media query for responsive design if needed */
@media screen and (max-width: 540px) {
  /* Add your responsive styles here */
}
