/* UserStats.css */

.userStatsMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userStatsSecond {
  padding: 1cap;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 120px;
  margin-bottom: 20px;
  width: 95%;
  align-items: center;
  justify-content: center;
}

/* Table1.css */
.table1 {
  border: 1px solid #ccc;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.103);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.table1 .table-row {
  /* Row styling for the first table */
  display: flex;
  justify-content: space-between;
  padding: 10px;
  min-width: 300px;
}

.table1 .table-cell {
  /* Cell styling for the first table */
  flex: 1;
}

/* Table2.css */
.table2 {
  border: 1px solid #ccc;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.103);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  margin: 20px;
}

.table2 .table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.table2 .table-cell {
  flex: 1;
}

.table-cell-header {
  font-weight: bold;
  background-color: #f2f2f2;
}

.table-cell-actions {
  width: 100px; /* Adjust the width as needed */
}

/* Add these classes to your existing structure */

.table-row > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.table-row > div > div {
  flex: 1;
}

.table-row > div > div:last-child {
  text-align: right;
}

.table3 {
border: 1px solid #ccc;
margin-bottom: 20px;
background-color: rgba(0, 0, 0, 0.103);
border-radius: 10px;
width: 100%;
margin: 20px;
display: flex;
flex-direction: column;
}

.table3 .header-row {
display: flex;
padding: 10px;
background-color: rgb(0, 0, 0);
color: white;
font-weight: bolder;
justify-content: space-around;
text-align: center;
border-radius: 10px;
}

.table3 .table-row {
display: flex;
flex-direction: column;
padding: 10px;
background-color: rgba(255, 255, 255, 0.456);
}

.table3 .table-cell {
flex: 1;
margin-bottom: 5px;
justify-content: space-around;
text-align: center;
}

.table3 .table-cell-header {
font-weight: bold;
min-width: 100px; /* Adjust the width as needed */
}





.table4 {
border: 1px solid #ccc;
margin-bottom: 20px;
background-color: rgba(0, 0, 0, 0.103);
border-radius: 10px;
width: 100%;
margin: 20px;
display: flex;
flex-direction: column;
}

.table4 .header-row {
display: flex;
padding: 10px;
background-color: rgb(0, 0, 0);
color: white;
font-weight: bolder;
justify-content: space-around;
text-align: center;
border-radius: 10px;
}

.table4 .table-row {
display: flex;
flex-direction: row;
padding: 10px;
background-color: rgba(255, 255, 255, 0.456);
}

.table4 .table-cell {
flex: 1;
margin-bottom: 5px;
justify-content: space-around;
text-align: center;
}

.table4 .table-cell-header {
font-weight: bold;
min-width: 100px; /* Adjust the width as needed */
}










.userStatsButton {
  margin: 10px;
  border-width: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.199);
  padding: 10px;
}

.userStatsMainList {
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.userStatsTableContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: space-around;
  align-items: center;
}


@media screen and (max-width: 600px) {

  .table1 {
    font-size: 12px;
  }

  .table2 {
    font-size: 12px;
  }

  .table3 {
    font-size: 12px;
  }

  .table-cell  {
    font-size: 12px;
  }

  .userStatsSecond {
    min-width: 320px;
  }

  .userStatsMainList {
    font-size: 12px;
    margin: 10px;
    padding: 5px;
  }
}

@media screen and (max-width: 500px) {
    .table-cell  {
    font-size: 12px;
  }
}
