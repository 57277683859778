.fixed-expenses-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 40px;
}

.fixed-expenses-Second {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px;
}

.expenses-table {
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
}

.expenses-table th, .expenses-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  border-radius: 10px;
}

.expenses-table th {
  background-color: #000000;
}

.trFixedExpenses {
  background-color: black;
}

.editFixedExpensesIcons {
  font-size: 15px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.editFixedExpensesIcons:hover {
  color: rgb(68, 49, 49);
  transform: scale(1.2);
}

/* Add these styles to your CSS file or style block */

.fixed-expenses-container {
  position: relative;
}

.delete-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for the overlay darkness */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other elements */
}

.delete-confirmation-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.delete-confirmation-modal button {
  margin: 0 10px;
}

.fixedExpensesDetailsBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
}

.fixedExpensesDetailsConvertBox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  cursor: pointer;
}

.fixedExpensesDetailsConvertBox:hover {
  background-color: #07b63539;
}


@media screen and (max-width: 600px) {
  .expenses-table th, .expenses-table td {
    font-size: 11px;
    padding: 2px;
    flex-direction: column;
    white-space: normal;
  }
}
