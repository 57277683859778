.loadMain {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
  }

  .loadForm {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: center;
    align-items: center;
  }

  .loadLabel {
    font-size: 14px;
    margin-right: 10px;
    font-weight: bold;
  }
