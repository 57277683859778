.warehouse-container {
  display: flex;
  justify-content: center;
  align-items: top;
  flex-direction: row;
  margin-top: 150px;
  }
    
    .warehouse-box-day {
      border: 1px solid #ccc;
      padding: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
      border-radius: 5px;
      display: flex;
      height: 60%;
      overflow-y: auto; /* Add a vertical scrollbar if content overflows */
      flex-direction: column;
      margin: 20px;
      justify-content: center;
      align-items: center;
      padding: 50px;
      min-width: 300px;
    }

    .warehouse-contact-item {
      margin-bottom: 10px;
    }
    
    .warehouse-contact-name {
      font-weight: 600;
      color: #2e2e2e;
      font-size: 14px;
    }
    
    .warehouse-material-type {
      color: #2e2e2e;
      font-weight: bold;
    }

    .userVisits {
      margin: 10px;
      border: solid;
      border-width: 1px;
      padding: 10px;
      width: 80%;
      border-radius: 5px;
      background-color: black;
      color: white;
    }

    .userVisitList {
      text-align: left;
      font-size: 12px;
    }
    

    @media screen and (max-width: 940px) {

      .warehouse-container {
          flex-direction: column;
        }

        .warehouse-box-day {
          border: 1px solid #ccc;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
          border-radius: 5px;
          display: flex;
          height: 60%;
          overflow-y: auto; /* Add a vertical scrollbar if content overflows */
          flex-direction: column;
          margin: 6px;
          justify-content: center;
          align-items: center;
          padding: 10px;
          min-width: 200px;
        }

        .warehouse-contact-name {
          font-size: 10px;
        }

        .warehouse-material-type {
          font-size: 10px;
        }

    }
    
