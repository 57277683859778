.goal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  margin-bottom: 40px;
}

.exports-second form {
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  align-items: center;
}

.goal-container-setgoal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:#282c34;
  margin: 5px;
  border: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.76);
  color: white;
  margin-top: 110px;
  margin-bottom: 15px;
  }

  .setGoal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

