.signupmain {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signupmain form {
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  align-items: center;
}


.bottombutton {
    color: black;
    margin-bottom: 50px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    width: 150px;
    background-color: #3d3d3519;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3);
  }

  .bottombutton:hover {
    background-color: black;
    color: white;
  }

  .form-label {
    color: #132334;
  }